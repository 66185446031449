<template>
  <div
    class="modal fade"
    id="modalValidationMessage"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalValidationMessageLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog sx-modal-responsive modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalValidationMessageLabel">
            <template
              v-if="
                businessRuleErrors.length > 0 &&
                (errors.length > 0 ||
                  warnings.length > 0 ||
                  informations.length > 0)
              "
            >
              <i class="fa fa-info-circle"></i>
              {{
                $t(
                  "Components.InlineErrorModal.ValidationRuleMessagesAndBusinessRuleErrors",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
            <template v-else-if="businessRuleErrors.length > 0">
              <i class="fa fa-bolt"></i>
              {{
                $t(
                  "BusinessRules.BusinessRuleErrors",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
            <template v-else>
              <i class="fa fa-info-circle"></i>
              {{
                $t(
                  "Components.InlineErrorModal.Title",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
          </h5>
          <button
            type="button"
            @click="close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ValidationMessageList
            :isModal="true"
            :errors="errors"
            :warnings="warnings"
            :informations="informations"
          />
          <BusinessRuleResponseGrid
            :parentClasses="errors || warnings || informations ? 'mt-2' : ''"
            :errors="businessRuleErrors"
            :response="businessRuleResponse"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="close"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BusinessRuleResponseGrid from "@/components/custom/set-pages/BusinessRuleResponseGrid.vue";
import ValidationMessageList from "./List.vue";
export default {
  name: "ValidationMessageModal",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    businessRuleResponse: {
      type: Object,
      default() {
        return {};
      },
    },
    businessRuleErrors: {
      type: Array,
      default: () => [],
    },
    warnings: {
      type: Array,
      default: () => [],
    },
    informations: {
      type: Array,
      default: () => [],
    },
    windowOpener: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    ValidationMessageList,
    BusinessRuleResponseGrid,
  },
  methods: {
    close() {
      // if (this.windowOpener) {
      //   window.close();
      // }
    },
  },
};
</script>

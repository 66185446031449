<template>
  <template v-if="!isModal">
    <div class="accordion mt-2 mb-2" id="accordionValidationRuleMessages">
      <div class="accordion-item">
        <h2 class="accordion-header" id="accordionValidationRuleMessagesHeader">
          <button
            class="accordion-button fs-5"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            {{
              $t(
                "Components.ValidationMessages.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="accordionValidationRuleMessagesHeader"
          data-bs-parent="#accordionValidationRuleMessages"
        >
          <div class="accordion-body">
            <div class="table-responsive">
              <table
                class="table table-primary fs-5 mt-1 mb-1"
                v-if="
                  !String.isNullOrWhiteSpace(msg) ||
                  !String.isNullOrWhiteSpace(message) ||
                  errors.length > 0 ||
                  warnings.length > 0 ||
                  informations.length > 0
                "
              >
                <thead>
                  <tr>
                    <th>
                      {{
                        $t(
                          "Components.ValidationMessages.Type",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th style="min-width: 150px">
                      {{
                        $t(
                          "Components.ValidationMessages.FieldName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $t(
                          "Components.ValidationMessages.Message",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="table-danger"
                    v-if="!String.isNullOrWhiteSpace(msg)"
                  >
                    <td>
                      {{
                        $t(
                          "BaseModelFields.Error",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </td>
                    <td>{{ $valueNotAvailableSeparator }}</td>
                    <th>{{ msg }}</th>
                  </tr>
                  <tr
                    class="table-danger"
                    v-if="!String.isNullOrWhiteSpace(message)"
                  >
                    <td>
                      {{
                        $t(
                          "BaseModelFields.Error",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </td>
                    <td>{{ $valueNotAvailableSeparator }}</td>
                    <th>{{ message }}</th>
                  </tr>
                  <tr class="table-danger" :key="i" v-for="(item, i) in errors">
                    <td>
                      {{
                        $t(
                          "BaseModelFields.Error",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </td>
                    <td v-if="!String.isNullOrWhiteSpace(item.fieldName)">
                      {{ item.fieldName }}
                    </td>
                    <td v-else>{{ $valueNotAvailableSeparator }}</td>
                    <td>{{ item.message }}</td>
                  </tr>
                  <tr
                    class="table-warning"
                    :key="i"
                    v-for="(item, i) in warnings"
                  >
                    <td>
                      {{
                        $t(
                          "BaseModelFields.Warning",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </td>
                    <td v-if="!String.isNullOrWhiteSpace(item.fieldName)">
                      {{ item.fieldName }}
                    </td>
                    <td v-else>{{ $valueNotAvailableSeparator }}</td>
                    <td>
                      {{ item.message }}
                      <button
                        type="button"
                        class="btn btn-success btn-force-save"
                        @click="forceSave"
                      >
                        <i class="fa fa-bolt"></i>
                        {{
                          $t(
                            "Components.ValidationMessages.ForceSave",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </button>
                    </td>
                  </tr>
                  <tr
                    class="table-info"
                    :key="i"
                    v-for="(item, i) in informations"
                  >
                    <td>
                      {{
                        $t(
                          "BaseModelFields.Information",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </td>
                    <td v-if="!String.isNullOrWhiteSpace(item.fieldName)">
                      {{ item.fieldName }}
                    </td>
                    <td v-else>{{ $valueNotAvailableSeparator }}</td>
                    <td>{{ item.message }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template
    v-else-if="
      errors.length > 0 || warnings.length > 0 || informations.length > 0
    "
  >
    <div class="table-responsive">
      <table class="table table-primary fs-5 mt-1 mb-1">
        <thead>
          <tr>
            <th>
              {{
                $t(
                  "Components.ValidationMessages.Type",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
            <th>
              {{
                $t(
                  "Components.ValidationMessages.FieldName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
            <th>
              {{
                $t(
                  "Components.ValidationMessages.Message",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-danger" :key="i" v-for="(item, i) in errors">
            <td>
              {{
                $t(
                  "BaseModelFields.Error",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </td>
            <td v-if="!String.isNullOrWhiteSpace(item.fieldName)">
              {{ item.fieldName }}
            </td>
            <td v-else>{{ $valueNotAvailableSeparator }}</td>
            <td>{{ item.message }}</td>
          </tr>
          <tr class="table-warning" :key="i" v-for="(item, i) in warnings">
            <td>
              {{
                $t(
                  "BaseModelFields.Warning",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </td>
            <td v-if="!String.isNullOrWhiteSpace(item.fieldName)">
              {{ item.fieldName }}
            </td>
            <td v-else>{{ $valueNotAvailableSeparator }}</td>
            <td>
              {{ item.message }}
              <button
                type="button"
                class="btn btn-success btn-force-save"
                @click="forceSave"
              >
                <i class="fa fa-bolt"></i>
                {{
                  $t(
                    "Components.ValidationMessages.ForceSave",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </button>
            </td>
          </tr>
          <tr class="table-info" :key="i" v-for="(item, i) in informations">
            <td>
              {{
                $t(
                  "BaseModelFields.Information",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </td>
            <td v-if="!String.isNullOrWhiteSpace(item.fieldName)">
              {{ item.fieldName }}
            </td>
            <td v-else>{{ $valueNotAvailableSeparator }}</td>
            <td>{{ item.message }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>
<script>
import { hideModal } from "@/core/helpers/dom";
export default {
  name: "ValidationMessageList",
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: () => [],
    },
    warnings: {
      type: Array,
      default: () => [],
    },
    informations: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    forceSave() {
      if (this.isModal) {
        hideModal(document.getElementById("modalValidationMessage"));
      }

      var relatedParentPage = this.$parent.$parent;
      setTimeout(() => {
        if (this.$route.meta.isNewPage) {
          relatedParentPage.onRecordInsert("0", "btn-save", true);
        } else if (this.$route.meta.isEditPage) {
          relatedParentPage.onRecordEdit("btn-edit", true);
        } else if (this.$route.meta.isCalendarPage) {
          if (relatedParentPage.crudType == "new") {
            relatedParentPage.saveEvent("btn-calendar-event-save", true);
          } else {
            relatedParentPage.updateEvent("btn-calendar-event-edit", true);
          }
        } else if (this.$route.meta.isTreePage) {
          relatedParentPage.save("btn-tree-record-save", true);
        }
      }, 400);
    },
  },
};
</script>
